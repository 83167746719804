<template>
  <div :style="$vuetify.breakpoint.smAndDown ? 'padding: 15% 5%' : 'padding: 6% 5%'
    ">
    <PageBreadcrumbs :data="breadcrumbs" />
    <vs-row justify="center">
      <h1>Designer Dresses</h1>
      <span class="material-symbols-outlined"> female </span>
    </vs-row>
    <Products :products="dresses" />
  </div>
</template>

<script>
export default {
  name: 'Dresses',
  components: {
    PageBreadcrumbs: () => import('../components/PageBreadcrumbs.vue'),
    Products: () => import('../components/Products.vue'),
  },
  data: () => ({
    breadcrumbs: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
      {
        text: 'Dresses',
        disabled: true,
      },
    ],
  }),
  metaInfo() {
    return {
      meta: [
        {
        name: 'description',
        content: 'Shop Bengalina’s dresses collection, where elegance meets modern style. From traditional designs to contemporary cuts, find the perfect dress for any occasion.',
      },
      {
        property: 'og:title',
        content: 'Elegant Dresses for Every Occasion | Bengalina',
      },
      {
        property: 'og:description',
        content: 'Browse Bengalina’s dresses collection, featuring timeless elegance and trendy cuts. Find your perfect fit in Dhaka’s top fashion destination for women’s dresses.',
      },
      {
        name: 'keywords',
        content: 'dresses collection Dhaka, elegant dresses Bangladesh, traditional dresses, modern dresses Dhaka, fashion dresses Bengalina, women’s dresses Bangladesh',
      },
      ]
    }
  },
  computed: {
    dresses() {
      return this.$store.state.dresses;
    },
  },
};
</script>
